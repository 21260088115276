//
// forms.scss
// Extended from Bootstrap
//


//
// Bootstrap Overrides ===================================
//

// Help text

.form-text {
  margin-top: 0;
  margin-bottom: $label-margin-bottom;
}


//
// Dashkit ===================================
//

// Form control flush
//
// Removes borders and paddings from inputs and text areas

.form-control-flush {
  padding: 0;
  border-width: 0;
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}


// Form control rounded
//
// Makes input border radius round

.form-control-rounded {
  border-radius: 20rem;
}


// Input group merge
//
// Makes input group text and form control visually the same element

.input-group.input-group-merge {

  > .form-control {
    border-radius: $input-border-radius;

    &:focus {
      box-shadow: none;
      
      ~ [class*="input-group"] {
        
        > .input-group-text {
          border-color: $input-focus-border-color;
        }
      }
    }
  }

  > .form-control.is-valid ~ [class*="input-group"] > .input-group-text {
    border-color: $success;
  }

  > .form-control.is-invalid ~ [class*="input-group"] > .input-group-text {
    border-color: $danger;
  }

  > .form-control-prepended {
    padding-left: $input-padding-x / 2;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0;
  }

  > .form-control-appended {
    padding-right: $input-padding-x / 2;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0;
  }

  > .input-group-prepend {
    order: -1; // prepend always goes first

    > .input-group-text {
      padding-right: $input-padding-x / 2;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      border-right-width: 0;
      transition: $input-transition;
    }
  }

  > .input-group-append {

    > .input-group-text {
      padding-left: $input-padding-x / 2;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border-left-width: 0;
      transition: $input-transition;
    }
  }
}

.input-group-lg.input-group-merge {

  > .form-control {
    border-radius: $input-border-radius-lg;
  }

  > .form-control-prepended {
    padding-left: $input-padding-x-lg / 2;

  }

  > .form-control-appended {
    padding-right: $input-padding-x-lg / 2;

  }

  > .input-group-prepend {

    > .input-group-text {
      padding-right: $input-padding-x-lg / 2;
      border-top-left-radius: $input-border-radius-lg;
      border-bottom-left-radius: $input-border-radius-lg;
    }
  }

  > .input-group-append {

    > .input-group-text {
      padding-left: $input-padding-x-lg / 2;
      border-top-right-radius: $input-border-radius-lg;
      border-bottom-right-radius: $input-border-radius-lg;
    }
  }
}

.input-group-sm.input-group-merge {

  > .form-control {
    border-radius: $input-border-radius-sm;
  }

  > .form-control-prepended {
    padding-left: $input-padding-x-sm / 2;

  }

  > .form-control-appended {
    padding-right: $input-padding-x-sm / 2;
  }

  > .input-group-prepend {

    > .input-group-text {
      padding-right: $input-padding-x-sm / 2;
      border-top-left-radius: $input-border-radius-sm;
      border-bottom-left-radius: $input-border-radius-sm;
    }
  }

  > .input-group-append {

    > .input-group-text {
      padding-left: $input-padding-x-sm / 2;
      border-top-right-radius: $input-border-radius-sm;
      border-bottom-right-radius: $input-border-radius-sm;
    }
  }
}

.input-group-rounded.input-group-merge {

  > .form-control {
    border-radius: 20rem;
  }

  > .input-group-prepend {

    > .input-group-text {
      border-top-left-radius: 20rem;
      border-bottom-left-radius: 20rem;
    }
  }

  > .input-group-append {

    > .input-group-text {
      border-top-right-radius: 20rem;
      border-bottom-right-radius: 20rem;
    }
  }
}

.input-group-flush {

  > .form-control {
    padding: 0;
    border-width: 0;
    background-color: transparent;
  }

  > .input-group-prepend,
  > .input-group-append {

    > .input-group-text {
      padding: 0;
      border-width: 0;
      background-color: transparent;
    }
  }
}